import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useWindowSize} from "../utils/useWindowSize";
import {generateRandomNumber, isWeixin} from "../utils";
import {Toast} from "antd-mobile";

const Container = styled.div`
    display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	
`
const DownloadBtn = styled.div`
    width: ${props => props.pc === 'pc' ? "260px":"20.5rem"};
    height:  ${props => props.pc === 'pc' ? '48px':"3.21rem"};
    background: #F44113;
    border-radius:${props => props.pc === 'pc' ? '24px':"1.5rem"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.pc === 'pc' ? '18px':"0.96rem"};
    color: #FFFFFF;
    line-height: ${props => props.pc === 'pc' ? 1:"3.21rem"};
	margin-top: ${props => props.pc === 'pc' ? '48px':"3.5rem"};
	cursor: pointer;
	
`
const BoldText = styled.div`
	font-size: 0.875rem;
	font-weight: bold;
	color: #18181A;
	margin-left: 0.5rem;
	text-align: center;
	line-height: 1.25rem;
	&:hover {
		color: ${props => props.hover === 'hover' ? '#7A57EB':'#18181A' };
	}
`
const CallText = styled.div`
	font-size: 0.875rem;
	color: #18181A;
	margin-left: 0.5rem;
	text-align: center;
	line-height: 1.25rem;
	&:hover {
		color: ${props => props.hover === 'hover' ? '#7A57EB':'#18181A' };
	}
`

const Download = () => {
	const {height,width} = useWindowSize()
	const [showArrow,setShowArrow] = useState(false)
	const [downUrl,setDownUrl] = useState('')
	// useEffect(() => {
	// 	fetch('https://www.xktruck.cn/apkUrl.json?time='+ new Date().getTime() + '&num=' +generateRandomNumber())
	// 		.then(response => response.json())
	// 		.then(data => {
	// 			if (data.url){
	// 				setDownUrl(data.url)
	// 			}
	// 			// 在这里可以使用获取到的 URL 进行后续操作
	// 		}).catch(error => console.error(error));
	// },[])
	function onDownApp() {
		if (isWeixin()){
			setShowArrow(true)
			return Toast.show({icon:'fail',content:"请在浏览器中打开"})
		}else {
			window.location.href = './marsx.apk'
		}
	}
	const isPc = () => {
		return width > 600
	}

    return (
        <Container style={{justifyContent:(width<600 && height < 1000)?"flex-start":'center',minHeight:height}}>
	        {(showArrow && !isPc() )&&
	        <div style={{position:"absolute",top:"1rem",right:"1rem"}}>
		        <img style={{width:"5rem",height:"5rem"}} src={require('../assets/images/base/share_arrow.png')} alt=""/>
		        <div style={{width:"5rem",color:"#B3B3B3"}}>点击上方按钮，选择浏览器中打开...</div>

	        </div>}
		        <img  style={{marginTop:(width<600 && height >700)?"4rem":"0rem",width:isPc()?315:"13.126rem",height:isPc()?315:"13.126rem",}}
		              src={require('../assets/images/download/20240808163147.png')} />
		        {/*<img  style={{width:isPc()?272.5:"11.353rem",height:isPc()?38.5:"1.603rem",marginTop:isPc()?48:"3.5rem"}}*/}
		        {/*      src={require('../assets/images/download/show_word.png')} />*/}
		        <DownloadBtn st  pc={isPc()?'pc':'mobile'} onClick={onDownApp}>
			        <img style={{width:isPc()?32:"2rem",height:isPc()?32:"2rem",marginRight:isPc()?24:"0.5rem"}} src={require('../assets/images/base/icon_share_download.png')} alt=""/>
			        <div>下载应用</div>
		        </DownloadBtn>
			<div style={{position:width>600?"fixed":"relative",marginTop:"6rem",bottom:width>600?"2rem":'0',cursor:"pointer",borderTop:"0.125rem solid #B3B3B3",
				width:isPc()?"80rem":"22.5rem",display:"flex",justifyContent:"center",alignItems:'center',flexDirection:"column"}}>
				{/*<div style={{display:"flex",flexDirection:isPc()?"row":"column",justifyContent:"flex-start",alignItems:'center',marginTop:"1rem",flexWrap:"wrap"}}>*/}
				{/*	<BoldText>联系电话:</BoldText>*/}
				{/*<div style={{display:"flex",justifyContent:"center",alignItems:'center'}}>*/}
				{/*	/!*<CallText>028-83958293</CallText>*!/*/}
				{/*	<CallText>+86-18980557908</CallText>*/}
				{/*</div>*/}
				{/*	<BoldText>邮箱:</BoldText>*/}
				{/*	<CallText>kefu@xktruck.com</CallText>*/}
				{/*</div>*/}
				{/*<div style={{display:"flex",justifyContent:"center",alignItems:'center',marginTop:"0.5rem"}} onClick={() => window.open('https://beian.miit.gov.cn/')} >*/}
				{/*	<BoldText onClick={() => window.open('https://www.xktruck.com/private.html')} hover={'hover'}>《隐私政策》</BoldText>*/}
				{/*	<BoldText onClick={() => window.open('https://www.xktruck.com/gamedoc.html')} hover={'hover'}>《服务协议》</BoldText>*/}
				{/*</div>*/}
				<div style={{display:"flex",justifyContent:"center",alignItems:'center',marginTop:"0.5rem"}}  >
					<BoldText>ICP备案号:</BoldText>
					<CallText hover={'hover'} onClick={() => window.open('https://beian.miit.gov.cn/')}>粤ICP备2024178646号-1</CallText>
				</div>

				{/*<div style={{marginTop:'0.5rem',display:"flex",justifyContent:"center",alignItems:'center'}}>*/}
				{/*	<CallText>Copyright © 2023-2024 www.xktruck.cn,</CallText>*/}
				{/*	<CallText hover={'hover'} onClick={() => window.open('http://www.xktruck.com/')}>www.xktruck.com</CallText>*/}
				{/*	<CallText>All Rights Reserved.小卡科技版权所有</CallText>*/}
				{/*</div>*/}
			</div>

        </Container>
    );
};

export default Download;

